.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 448px;
  height: auto;
  margin-top: 24px;
}

.image-container-medium {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 66%;
  height: auto;
  margin-top: 24px;
}

.image-container-large {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 24px;
}

@media (max-width: 1392px) {
  .image-container {
    width: 32%;
  }

  .image-container-medium {
    width: 64%;
  }
}

@media (max-width: 880px) {
  .image-container {
    width: 100%;
    text-align: center;
    margin: 32px auto;
  }

  .image-container-medium {
    text-align: center;
    margin: 32px auto;
    width: 100%;
  }

}