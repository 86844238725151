.detail-container {
  margin-top: calc(58px + 32px);
  margin-bottom: 64px;
}

.details {
  width: 1392px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px auto;
}

.detail-image-container {
  margin: 0px auto;
}

.product-section {
  width: 65%;
}

.product-section .description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description-text {
  width: 80% !important;
}

.product-section h1 {
  width: 80%;
}

.product-section p {
  width: 80%;
}

.product-section .description-image {
  align-self: flex-start;
}

.product-section p, .product-section p img {
  width: 100%;
}

.product-section h1 span {
  font-size: 26px;
  color: #2D2D2D;
  font-family: 'DrukWide-Super', sans-serif;
  letter-spacing: 0.43px;
}

.product-section p span {
  color: #3C4043;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.info-section {
  position: relative;
  width: 428px;
}

.info-section-sticky {
  position: fixed;
  width: 428px;
}

.info-title {
  width: 90%;
  font-family: 'DrukWide-Medium', sans-serif;
  font-size: 20px;
}

.info-description {
  margin-top: 24px;
  font-family: 'Times New Roman', sans-serif;
  font-size: 16px;
  color: #3C4043;
  line-height: 20px;
}

.info-body {
  margin-top: 32px;
  font-family: 'Inter-Regular';
  font-size: 18px;
}

.info-body .description {
  font-size: 16px;
  line-height: 1.2rem;
}

.info-body .separator {
  margin-top: 64px;
  width: inherit;
  border-top: 1px solid #B9B9B9;
}

.info-body .price {
  margin-top: 32px;
  font-size: 18px;
}

.variants {
  /* margin-top: 32px; */
  display: flex;
  flex-direction: row;
  width: auto;
}

.button-container {
  margin-top: 32px;
}

.button-container .btn {
  font-family: 'DrukWide-Medium', sans-serif;
  color: white;
  background-color: #1B1B1B;
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  border-radius: 26px;
  width: 100%;
  height: 52px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-container .btn:hover {
  background-color: #0f0f0f;
}

.button-container .btn:active {
  background-color: #0a0a0a;
}

.btn-sold-out {
  font-family: 'DrukWide-Medium', sans-serif;
  color: white;
  background-color: #bababa;
  font-size: 11px;
  border: none;
  border-radius: 26px;
  width: 100%;
  height: 52px;
  cursor: not-allowed;
}

.detail-icon {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  cursor: pointer;
  opacity: 0.5;
  height: 24px;
}

.detail-icon-back {
  left: 8px; 
}

.detail-icon-next {
  right: 8px;
}

.detail-icon:hover {
  opacity: 0.75;
}

.detail-error-message {
  font-family: 'Times New Roman', sans-serif;
  font-size: 16px;
  margin: 12px 0 0 4px;
  /* text-align: center; */
  color: rgb(255, 45, 45);
}

@media (max-width: 1392px) {
  .details {
    width: 100%;
  }

  .product-section {
    width: 60%;
  }
}

@media (max-width: 1120px) {
  .details {
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
  }

  .info-section {
    width: 80%;
    margin: 32px auto;
  }

  .info-section-sticky {
    position: relative;
    margin: 0 auto;
  }

  .detail-image-container {
    width: 100%;
  }

  .product-section {
    margin: 0px auto;
    width: 100%;
  }

  .description .description-image {
    margin: 0 auto;
  }

  .product-section .description-image img {
    width: 100%;
  }

  p span img {
    width: 100%;
  }

}

@media (max-width: 520px) {
  .info-section-sticky {
    width: auto;
  }
}