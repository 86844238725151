.footer {
  position: absolute;
  width: calc(100% - 48px);
  font-family: 'Times New Roman', Times, serif;
  font-size: 13px;
  letter-spacing: 0.18px;
  background-color: black;
  color: #B1B1B1;
  height: 340px;
  padding: 24px;
  z-index: 999;
}

.footer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-logo {
  height: 87px;
}

.footer-links {
  width: 100%;
}

.footer-links ul {
  padding: 0;
  margin: 0 auto;
  width: 80%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-links a, .footer-links a:hover, .footer-links a:focus, .footer-links a:active {
  text-decoration: none;
  color: inherit;
}

.faq-and-policies-container {
  margin: 64px 0;
}

.f-a-p-content {
  width: 524px;
  margin: 0 auto;
  padding: 32px 0 96px;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1.4em;
  color: #3C4043;

}

.f-a-p-content h1 {
  font-family: 'DrukWide-Super', sans-serif;
  font-size: 26px;
  color: #2D2D2D;
  letter-spacing: 0.5px;
}

@media (max-width: 880px) {
  .footer-logo {
    width: 90%;
    height: auto;
  }

  .faq-and-policies-container {
    margin: 64px auto 0;
    width: 80%;
  }

  .f-a-p-content {
    width: 80%;
  }

  .f-a-p-content span {
    width: 100%;
    word-wrap: break-word;
  }

  /* .footer-links ul {
    flex-direction: column;
    align-items: center;
  } */

}