@font-face {
  font-family: 'DrukWide-Medium';
  src: local('DrukWide-Medium'), url(./assets/DrukWide-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'DrukWide-Super';
  src: local('DrukWide-Super'), url(./assets/DrukWide-Super.otf) format('opentype');
}

/* :root{ scrollbar-width: none !important }  */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {outline:0;}

:focus {outline:none;}
::-moz-focus-inner {border:0;}