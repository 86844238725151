.archive-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.archive-header {
  display: flex;
  justify-content: center;
  z-index: -1;
  height: 270px;
  margin-top: 312px;
}

.archive-header img {
  position: fixed;
  align-self: center; 
}

.archive-list-container {
  margin: 64px 0;
}

.archive-list {
  width: 1392px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 1392px) {
  .archive-header img {
    width: 90%;
  }

  .archive-list {
    width: 90%;
  }

}

@media (max-width: 880px) {
  .archive-header {
    margin-top: 96px;
  }

  .archive-list {
    width: 90%;
    flex-direction: column;
    align-items: center;
    width: auto;
  }
}