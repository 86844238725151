.variants-section {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.variants-color-section {
  display: flex;
  flex-direction: column;
}

.variants-color-header {
  width: 100%;
  color: #3C4043;
  font-family: 'Times New Roman', Times, serif;
}

.variants-colors {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

.variants-color-dot {
  height: 24px; 
  width: 24px; 
  border-radius: 50%;
  cursor: pointer;
  margin-right: 24px;
}

.variants-size-section {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #B9B9B9;
}

.variants-size-header {
  margin-top: 12px;
  width: 100%;
  color: #3C4043;
  font-family: 'Times New Roman', Times, serif;
}

.variants-sizes {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

.variants-size-container {
  width: 32px;
  height: 32px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
  margin-right: 16px;
}

.variants-size-container-selected {
  width: 32px;
  height: 32px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  margin-right: 16px;
}

.variants-size-text {
  position: relative;
  top: 5px;
  font-family: 'DrukWide-Medium', sans-serif;
}

.variants-size-text:hover {
  color: #3C4043;
}