.cart-container {
  font-family: 'Times New Roman', Times, serif;
  color: #646464;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 58px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.8);
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-duration: 0.3s
}

.cart-content {
  position: fixed;
  background-color: #fefefe;
  right: 0;
  top: 58px;
  padding: 0px 24px;
  width: 496px;
  height: 90%;
}

.cart-items-container {
  overflow: scroll;
  height: 75%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.cart-items-container::-webkit-scrollbar {
  display: none;
}

.cart-item {
  padding: 32px 0px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #B9B9B9;
}

.cart-item:nth-child(-n+1) {
  padding: 0px 0px 32px;
}

.cart-item:nth-last-child(1) {
  border-bottom: none;
}

.cart-item-title {
  font-family: 'DrukWide-Super', sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #2D2D2D;
}

.cart-variant, .cart-item-quantity, .cart-item-price {
  font-size: 16px;
  color: #646464;
  letter-spacing: 0.25px;
}

.cart-item-remove {
  font-family: Arial;
  float: right;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}

.cart-item-info {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 100;

}

.cart-image-container {
  background-size: cover;
  background-position: center;
  height: 94px;
  width: 94px;
}

.cart-item-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart-footer {
  width: 496px;
  height: 25%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #B9B9B9;
}

.subtotal-contents {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  font-size: 16px;
  color: black;
}

.checkout-button-container {
  padding: 24px 0px;
}

.checkout-button {
  font-family: 'DrukWide-Medium', sans-serif;
  color: white;
  background-color: #1B1B1B;
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  border-radius: 26px;
  width: 100%;
  height: 52px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.checkout-button a {
  color: white !important;
  width: 100%;
  height: 100%;
}

.cart-empty {
  text-align: center;
  font-family: 'DrukWide-Medium', sans-serif;
  margin-top: 48px;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {top: -300px; opacity: 0} 
  to {top: 58px; opacity: 1}
}

@keyframes slideIn {
  from {top: -300px; opacity: 0}
  to {top: 58px; opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0} 
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0} 
  to {opacity: 1}
}

@media (max-width: 880px) {

  .cart-content {
    width: 90%;
    min-height: 90%;
  }

  .cart-footer {
    width: 90%;
  }

}