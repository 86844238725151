.archive-item-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  text-align: center;
  height: auto;
  margin-top: 24px;
}

.archive-item-container-medium {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  width: 66%;
  height: auto;
  margin-top: 24px;
}

.archive-item-container-large {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.archive-video {
  width: 448px;
}

.archive-item-images {
  width: 100%;
  margin: auto;
}

.archive-item-container-medium .archive-item-images {
  margin: 0 auto;
}

.archive-item-container-medium img {
  width: 100%;
}

.archive-item-container-large .archive-item-images {
  width: 100%;
}

.archive-product-image-1 {
  width: 448px;
}

@media (max-width: 1392px) {
  .archive-item-container {
    width: 32%;
  }

  .archive-product-image-1 {
    width: 100%;
  }
  
}

@media (max-width: 880px) {
  .archive-item-container {
    width: 90%;
  }

  .archive-item-container-medium {
    width: 90%;
  }

  .archive-item-container-large {
    width: 90%;
  }
}