.product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: left;
  width: 448px;
  height: 100%;
  margin-top: 24px;
}

.product-container-medium {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  height: auto;
  margin-top: 24px;
}

.product-title {
  font-family: 'DrukWide-Medium', sans-serif;
  position: absolute;
  bottom: 4px;
  left: 16px;
  font-size: 9px;
  letter-spacing: 1.44px;
  color: #000;
}

.product-link-container {
  text-decoration: none;
}

.product-price {
  margin-top: 4px;
  font-size: 14px;
}

.product-images {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.product-image-1 {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .3s cubic-bezier(.25,.46,.45,.94);
}

@media (max-width: 1392px) {
  .product-link-container {
    width: 32%;
  }

  .product-container {
    width: 100%;
  }
}

@media (max-width: 880px) {
  .product-link-container {
    width: 100%;
  }

  .product-container {
    text-align: left;
    margin: 32px auto;
  }

  .product-container-medium {
    text-align: left;
    margin: 32px auto;
    height: auto;
  }

  .product-container-medium > .product-image-container > .product-images > .product-image-1 {
    /* width: 90%; */
  }
}