.product-list-container {
 margin: 64px 0;
}

.product-list {
  width: 1392px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 1392px) {
  .product-list {
    width: 90%;
  }

}

@media (max-width: 880px) {
  /* .product-list-container {
    margin-top: calc(58px + 32px);
  } */
  
  .product-list {
    display: flex;
    align-items: center;
    width: 90%;
  }
}