nav {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 99999 !important;
  overflow: hidden;
}

nav a:hover,
.nav-title:hover {
  cursor: pointer;
}

.logo {
  margin-left: 24px;
}

.logo svg {
  height: 28px !important;
}

.logo:hover {
  fill: #0d8ae1;
}

.nav-title {
  padding: 4px;
  margin-left: 24px;
  text-align: center;
  font-size: 22px;
  color: #3C4043;
  letter-spacing: 2px;
}

.nav-left {
  display: flex;
  flex-direction: row;
}

.nav-left ul {
  margin: 0 0 0 48px;
  font-family: 'DrukWide-Medium', sans-serif;
  color: #3C4043;
  display: flex;
  list-style: none;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0.2px;
}

.nav-left ul a {
  text-decoration: none;
  color: inherit;
  margin-right: 24px;
}

.nav-links {
  font-family: 'DrukWide-Medium', sans-serif;
  margin-right: 8px;
  display: flex;
  list-style: none;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0.2px;
}

.nav-links a, .nav-links span {
  color: #3C4043;
  text-decoration: none;
  font-size: 11px;
  padding: 0 20px;
  vertical-align: middle;
  cursor: pointer;
}

.nav-burger {
  display: none;
  cursor: pointer;
}

.nav-burger div {
  width: 28px;
  height: 2px;
  background-color: black;
  margin: 6px 0;
}

@media (max-width: 880px) {

  nav {
    justify-content: space-between;
  }

  .logo {
    margin-top: 2px;
  }

  .nav-burger {
    display: block;
    margin-left: 24px;
  }

  .nav-mobile-links {
    display: none;
  }
  
  .links-burger {
    left: 0;
    position: block;
    margin-top: 58px;
    width: 100%;
    background-color: #fff;
  }

  .nav-expanded {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: -24px;
  }

  .nav-expanded a {
    padding: 12px 24px;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    margin: 0px auto;
  }

  .nav-title {
    margin: 0px;
  }

}