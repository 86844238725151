.variant-container {
  width: 48px;
  height: 48px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
  margin-right: 16px;
}

.variant-text {
  position: relative;
  top: 12px;
  font-family: 'DrukWide-Medium', sans-serif;
}

.variant-container-selected {
  width: 48px;
  height: 48px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  margin-right: 16px;
}