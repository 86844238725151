.app-container {
  min-height: calc(100vh - 452px);
}

.first-time-container {
  display: flex;
  align-items: center;
  background: #fff;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
